import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import sparkle1 from "../Assets/sparkles_VP8.webm"
import sparkle2 from "../Assets/sparkles2_VP8.webm"
import {wait} from "@testing-library/user-event/dist/utils";

type props = {
    answer: string,
    correct: boolean,
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    class: string;
}

export const AnswerButton = (props: props) => {

    const sparkleVideoTop = useRef<HTMLVideoElement>(null)
    const sparkleVideoBottom = useRef<HTMLVideoElement>(null)

    return (
        <div onClick={() => {
            if (props.correct) {
                if(sparkleVideoTop.current !== null && sparkleVideoBottom.current !== null) {
                    sparkleVideoTop.current.playbackRate = 2;
                    sparkleVideoBottom.current.playbackRate = 2;
                }
                sparkleVideoTop.current?.play();
                wait(250).then(r => sparkleVideoBottom.current?.play())
            }
        }
        } className={"button-div"}>
            {props.correct && <video ref={sparkleVideoTop} src={sparkle2} className={"video-top-left"}/>}
            <button onClick={props.onClick} className={props.class}>{props.answer}</button>
            {props.correct &&
                <video ref={sparkleVideoBottom} src={sparkle1} className={"video-bottom-right"}/>}
        </div>

    )

}
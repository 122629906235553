import './StartPage.scss'
import comm from "../Assets/comm.svg"
import design from "../Assets/design.svg"
import dev from"../Assets/dev.svg"
import pm from "../Assets/pm.svg"
import ux from "../Assets/ux.svg"
import video from "../Assets/video.svg"


import {useNavigate} from "react-router-dom";
import {useState} from "react";
export const StartPage = () =>{

    const [clicked,setClicked] = useState<boolean>(false)

    const navigate = useNavigate()

return (
    <div className='start-page'>
        <div className='title-box'>
            <h1>Test Your Media Knowledge</h1>
        </div>

        <div className='description-box'>
            <h3>Lets Get Started!</h3>
            <h3>Select the discipline/industry you'd like to test you knowledge on: </h3>
        </div>

        <div className='icon-box'>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/ux")
                },500)
            }}>
                <img src={ux} alt="User Experience team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#EFC519"}}>User Experience</p>
            </div>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/video")
                },500)
            }}>
                <img src={video} alt="Video team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#EF4255"}}>Video</p>
            </div>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/dev")
                },500)
            }}>
                <img src={dev} alt="Development team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#EE7526"}}>Development</p>
            </div>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/design")
                },500)
            }}>
                <img src={design} alt="Design team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#277EC3"}}>Design</p>
            </div>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/comm")
                },500)
            }}>
                <img src={comm} alt="Communication team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#262F6A"}}>Communication</p>
            </div>
            <div className={"icon"} onClick={() => {
                setTimeout(()=>{
                    navigate("/pm")
                },500)
            }}>
                <img src={pm} alt="PM team" className={clicked ? "expand" : ""} onClick={() => { setClicked(true)}}/>
                <p style={{color: "#52C5D8"}}>Project Management</p>
            </div>
        </div>
    </div>
)
}
import React from 'react';
import {StartPage} from './Pages/StartPage';
import {QuestionPage} from './Pages/QuestionPage';
import './App.css';
import {ResultsPage} from "./Pages/ResultsPage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

function App() {

    //document.addEventListener('contextmenu', event => event.preventDefault());

    return (
        <Router>
            <div className="App">
                <meta name="viewport"
                      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
                <Routes>
                    <Route path="/" element={<StartPage/>}/>
                    <Route path="/:category" element={<QuestionPage/>}/>
                    <Route path="/:category/:points" element={<ResultsPage/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;

import './ResultsPage.scss'
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";
import sparkle1 from "../Assets/sparkles_VP8.webm"
import sparkle2 from "../Assets/sparkles2_VP8.webm"

export const ResultsPage = () => {

    const {category, points} = useParams();

    const convertedCategory = () => {
        switch (category) {
            case "dev":
                return "Development"
            case "comm":
                return "Communications"
            case "design":
                return "Design"
            case "ux":
                return "UX"
            case "video":
                return "Video"
            case "pm":
                return "Project Management"
        }
    }

    return (
        <div className='results-page'>
            <div className='title-box'>
                <h1 style={{margin: 0}}>Test Your Media Knowledge</h1>
            </div>
            <div className={"response-box"}>
                {Number(points) < 2 &&
                    <h2>Keep Trying! There’s a lot to learn about {convertedCategory()}, and you’re off to a good
                        start!</h2>}
                {Number(points) >= 2 &&
                    <h2>Media Master! You’re a {convertedCategory()} whiz! Have you checked out the Digital Corps?</h2>}
            </div>
            <Link to={"/"}>
            <div className={"home-button"}>
                <p>select another discipline you’d like to test your knowledge on</p>
            </div>
            </Link>
            <video loop autoPlay={true} src={sparkle1} className={"sparkle-left"} ></video>
            <video loop autoPlay={true} src={sparkle2} className={"sparkle-right"} ></video>
        </div>
    )
}
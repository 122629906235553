import './QuestionPage.scss'
import questions from "../questions.json"
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AnswerButton} from "../Components/AnswerButton";

export const QuestionPage = () => {

    //TODO: Animations:

    const timeout = (delay: number) => {
        return new Promise(res => setTimeout(res, delay));
    }

    const [timeoutTime, setTimeoutTime] = useState(0)

    const shuffle = (array: any) => {
        let newArray = [...array];
        for (let i = newArray.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = newArray[i];
            newArray[i] = newArray[j];
            newArray[j] = temp;
        }
        return newArray
    }

    const {category} = useParams()
    const navigate = useNavigate()

    const [questionList, setQuestionList] = useState(questions)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
    const [currentQuestion, setCurrentQuestion] = useState(questionList[0])
    const [currentChoices, setCurrentChoices] = useState(currentQuestion.choices)

    const [clickedIndex, setClickedIndex] = useState(100)

    const [points, setPoints] = useState(0)
    const [guessing, setGuessing] = useState(true)
    const [correct, setCorrect] = useState(true)
    const [answerRevealed, setAnswerRevealed] = useState(false)
    const [waiting, setWaiting] = useState(false)


    useEffect( ()  =>{
        if (timeoutTime == 0){
            return
        }
        timeout(timeoutTime).then(()=>{
            setWaiting(false)
            setGuessing(false)
            setTimeoutTime(0)
        })
    },[timeoutTime])

    useEffect(() => {
        setQuestionList(shuffle(questions.filter((question) => question.category === category)))
        setCurrentChoices(shuffle(currentChoices))
    }, [])

    useEffect(() => {
        setCurrentQuestion(questionList[currentQuestionIndex])
    }, [currentQuestionIndex, questionList])

    useEffect(() => {
        setCurrentChoices(shuffle(currentQuestion.choices))
        setClickedIndex(100)
        setAnswerRevealed(false)
    }, [currentQuestion])

    const convertedCategory = () => {
        switch (category) {
            case "dev":
                return "Development"
            case "comm":
                return "Communications"
            case "design":
                return "Design"
            case "ux":
                return "UX"
            case "video":
                return "Video"
            case "pm":
                return "Project Management"
        }
    }

    const selectAnswer = async (text: string) => {
        if (answerRevealed) {
            return
        }
        setAnswerRevealed(true)
        if (text === currentQuestion.answer) {
            setTimeoutTime(3500)
            setWaiting(true)
            setPoints(points + 1)
            setCorrect(true)
        } else {
            setTimeoutTime(1200)
            setWaiting(true)
            setCorrect(false)
        }
    }

    const nextQuestion = () => {
        setCorrect(false)
        if (currentQuestionIndex != questionList.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1)
            setGuessing(true)
        } else {
            let path = `/${category}/${points}`
            navigate(path)
        }
    }

    const getClass = (index: number, text: string) => {
        if (clickedIndex === index && text === currentQuestion.answer) {
            return "correctButton"
        } else if (clickedIndex === index && text !== currentQuestion.answer) {
            return "incorrectButton"
        } else {
            return "defaultButton"
        }
    }

    const handleAnswerSelect = (choice: string, index: number) => {
        selectAnswer(choice).then(() => console.log())
        setClickedIndex(index)
    }


    return (
        <div className='question-page'>
            <div className={"content-container"}>
                <h1 className={"title-box"}>Test Your Media Knowledge</h1>
                <div className='question-box'>
                    <h1 className={"category-tag"}>{convertedCategory()}</h1>
                    <h1>{currentQuestion.question}</h1>
                </div>

                <div className={!waiting && answerRevealed ? 'button-box-none' : "button-box"}>
                    {currentChoices.map((choice, index) => {
                        if (choice !== "") {
                            return <AnswerButton onClick={() => handleAnswerSelect(choice, index)} key={index}
                                           class={getClass(index, choice)} correct={choice===currentQuestion.answer}
                            answer={choice}/>
                        }
                    })}
                </div>

                {!guessing && answerRevealed && !waiting && correct &&
                    <div className={"correct-response-box"}>
                        <p className={"pun"}>{currentQuestion.correctPun}</p>
                        <p>{currentQuestion.message}</p>
                    </div>}
                {!guessing && answerRevealed && !waiting && !correct &&
                    <div className={"incorrect-response-box"}>
                        <p className={"pun"}>{currentQuestion.incorrectPun}</p>
                        <p>{currentQuestion.message}</p>
                    </div>}
                {!guessing && answerRevealed && !waiting &&
                    <button className={"nextButton"} onClick={nextQuestion}>Next Question</button>}

            </div>
        </div>
    )
}